@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;700;900&display=swap");

body,
#root {
  font-family: "Nunito Sans", sans-serif;
}

.carousel .slide img {
  width: inherit !important;
}

.carousel.carousel-slider,
.carousel .slider-wrapper {
  overflow: visible !important;
}
